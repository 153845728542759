import { useTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LoginForm from "../components/LoginForm";
import ResetPassword from "../components/ResetPassword";

function LoginScreen() {
  const { t } = useTranslation();

  return (
    <Container style={{ paddingTop: "10%" }}>
      <Row className="justify-content-center align-items-center">
        <Col md="5" className="align-items-left">
          <h2 className="mb-4">{t("loginScreen.login")}</h2>
          <LoginForm />
          <ResetPassword />
        </Col>
      </Row>
    </Container>
  );
}

export default LoginScreen;
