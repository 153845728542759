import pdfMake from "pdfmake/build/pdfmake";
import htmlToPdfmake from "html-to-pdfmake";
import pdfFonts from "../assets/vfs_fonts";
import { useState } from "react";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
  Avenir: {
    normal: "Avenir-Book.ttf",
    medium: "Avenir-Medium.ttf",
    bold: "Avenir-Heavy.ttf",
  },
  Sentient: {
    normal: "Sentient-Regular.ttf",
    italics: "Sentient-Italic.ttf",
    medium: "Sentient-Medium.ttf",
    mediumitalics: "Sentient-MediumItalic.ttf",
    bold: "Sentient-Bold.ttf",
    bolditalics: "Sentient-BoldItalic.ttf",
  },
  "EB Garamond": {
    normal: "EBGaramond-Regular.ttf",
    italics: "EBGaramond-Italic.ttf",
    medium: "EBGaramond-Medium.ttf",
    mediumitalics: "EBGaramond-MediumItalic.ttf",
    bold: "EBGaramond-SemiBold.ttf",
    bolditalics: "EBGaramond-SemiBoldItalic.ttf",
  },
};
export function usePdf() {
  const [loading, setLoading] = useState(false);

  function createPdf(ref, author, { title, page }) {
    setLoading(true);

    const html = htmlToPdfmake(ref.outerHTML, {
      imagesByReference: true,
      removeExtraBlanks: true,
      defaultStyles: {
        p: {
          font: "EB Garamond",
          lineHeight: 1.2,
          alignment: "justify",
          margin: [0, 0, 0, 12],
        },
        h1: {
          font: "EB Garamond",
          bold: true,
          italics: true,
          marginTop: 60,
          marginBottom: 250,
          alignment: "center",
        },
        h2: {
          font: "EB Garamond",
        },
        h3: {
          font: "EB Garamond",
        },
        h4: {
          font: "EB Garamond",
          margin: [0, 12, 0, 24],
          bold: true,
          italics: false,
        },
        h5: {
          font: "EB Garamond",
        },
        h6: {
          font: "EB Garamond",
        },
        figure: {
          pageBreakInside: "avoid",
          breakInside: "avoid",
          keepTogether: true,
          unbreakable: true,
        },
        "image-figure": {
          pageBreakInside: "avoid",
          breakInside: "avoid",
          keepTogether: true,
          unbreakable: true,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        },
        figcaption: {
          font: "EB Garamond",
          fontSize: 10,
          color: "#444",
          alignment: "center",
        },
      },
    });
    const pdfSettings = {
      content: html.content,
      info: {
        title,
        author,
      },
      images: html.images,
      pageSize: "A5",
      styles: {
        "html-img": {
          alignment: "center",
          marginTop: 16,
        },
        "image-figure": {
          pageBreakInside: "avoid",
          breakInside: "avoid",
          keepTogether: true,
          unbreakable: true,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        },
        headline: {
          alignment: "center",
          font: "Sentient",
          bold: true,
          italics: true,
        },
        "author-text": {
          alignment: "center",
          characterSpacing: 1,
          marginBottom: 5,
        },
        "last-page-text": {
          alignment: "center",
          fontSize: 10,
        },
        "last-page-logo": {
          margin: [0, 380, 0, 14],
        },
      },
      defaultStyle: {
        font: "Avenir",
      },
      pageBreakBefore: function (currentNode) {
        const hasPageBreakStyle = currentNode.style?.includes(
          "pdf-pagebreak-before",
        );
        const isHeadline = currentNode.style?.includes("headline");

        // Check position using verticalRatio from startPosition
        const isAtPageStart = currentNode.startPosition?.verticalRatio < 0.1;

        // Only break if element has required styles and isn't at page start
        return hasPageBreakStyle && isHeadline && !isAtPageStart;
      },
      pageMargins: [50, 50, 50, 70],
      figure: {
        unbreakable: true,
      },
      footer: function (currentPage, pageCount) {
        if (
          currentPage === 1 ||
          currentPage === 2 ||
          currentPage === pageCount
        ) {
          return;
        }
        return currentPage % 2 === 0
          ? {
              columns: [
                {
                  width: "auto",
                  text: `${currentPage.toString()}`,
                  bold: true,
                  fontSize: 8,
                  marginTop: 17,
                  marginLeft: 50,
                },
                {
                  width: "auto",
                  text: "|",
                  fontSize: 20,
                  margin: [6, 10, 6, 0],
                },
                {
                  width: "auto",
                  text: title.toUpperCase(),
                  fontSize: 8,
                  marginTop: 17,
                },
              ],
            }
          : {
              columns: [
                {
                  width: "*",
                  text: "",
                },
                {
                  width: "auto",
                  text: author?.toUpperCase(),
                  fontSize: 8,
                  marginTop: 17,
                },
                {
                  width: "auto",
                  text: "|",
                  fontSize: 20,
                  margin: [6, 10, 6, 0],
                },
                {
                  width: "auto",
                  text: `${currentPage.toString()}`,
                  bold: true,
                  fontSize: 8,
                  marginTop: 17,
                  marginRight: 50,
                },
              ],
            };
      },
    };

    pdfMake.createPdf(pdfSettings).download(() => setLoading(false));
  }

  return {
    createPdf,
    loading,
  };
}
