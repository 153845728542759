import {useUserData} from './useUserData';
import {useState} from 'react';
import {SKIPPED_ANSWER_VALUE} from '../constants/common';
import {fetchChatGPTSimple} from '../utils/book';

export const useBookData = (
  user,
  onEnd,
  minGenNum = 1,
  maxGenNum = 50,
  model,
  language,
) => {
  const { userData, userQuestionsWithAnswers } = useUserData(user);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState({});

  const bookQaData = userData?.book_qa_data;

  const modifiedUserQuestions = userQuestionsWithAnswers
  ?.filter(q => {
    if (!q || !q.answers || !q.answers[0] || !q.answers[0]['stripped-text']) {
      return true;
    }

    return q.answers[0]['stripped-text'] !== SKIPPED_ANSWER_VALUE;
  })
  ?.filter(q =>
    minGenNum <= Number(q?.questionId) &&
    Number(q?.questionId) <= maxGenNum,
  )
  ?.map((item) => {
    let singleTextAnswer = "";

    item?.answers?.forEach((a) => {
      if (a?.["stripped-text"] && a?.["stripped-text"] !== null) {
        singleTextAnswer += a?.["stripped-text"];
      }
    });

    return {
      questionId: item?.questionId,
      question: item?.question,
      questionAsTitle: item?.questionAsTitle,
      answer: singleTextAnswer,
      photos: item?.photos,
    };
  });

  const generateChapters = async () => {
    try {
      setLoading(true);
      const texts = (answer) => ({
        en: {
          48: `Rewrite TEXT. Firstly, proofread text by fixing grammar and syntax. Secondly, split this text into paragraphs and format paragraphs with html <p> tags. Thirdly, highlight the book title from the text with <b> html tag. Return only TEXT. TEXT: "${answer}"`,
          other: `Rewrite TEXT. Firstly, proofread text by fixing grammar and syntax. Secondly, split this text into paragraphs and format paragraphs with html <p> tags. Return only TEXT. TEXT: "${answer}"`,
        },
        es: {
          48: `Reescribe el TEXTO. En primer lugar, corrige el texto revisando la gramática y la sintaxis. En segundo lugar, divide este texto en párrafos y formatea los párrafos con etiquetas HTML <p>. En tercer lugar, resalta el título del libro en el texto con la etiqueta HTML <b>. Devuelve solo el TEXTO. TEXTO: "${answer}"`,
          other: `Reescribe el TEXTO. En primer lugar, corrige el texto revisando la gramática y la sintaxis. En segundo lugar, divide este texto en párrafos y formatea los párrafos con etiquetas HTML <p>. Devuelve solo el TEXTO. TEXTO: "${answer}"`,
        },
      });

      const promises = modifiedUserQuestions.map(async (item) => {
        if (item?.answer) {
          const prompt =
            Number(item?.questionId) === 48
              ? texts(item?.answer)?.[language]?.[48]
              : texts(item?.answer)?.[language]?.other;
          return fetchChatGPTSimple(prompt, model, language);
        }
      });
      const results = await Promise.all(promises);
      const merged = modifiedUserQuestions?.map((item, idx) => ({
        ...item,
        answer: results?.[idx],
      }));

      setData(merged);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
      onEnd();
    }
  };

  const constructBookData = () => {
    const merged = modifiedUserQuestions?.map(item => ({
      ...item,
      answer: bookQaData?.[Number(item?.questionId)]?.text,
    }));
    setData(merged);
  };

  return { generateChapters, constructBookData, loading, error, data };
}
