import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import AnswerItem from "./AnswerItem";
import { useTranslation } from "react-i18next";

const AllAnswers = ({ isLoading, questionsWithAnswers }) => {
  const { t } = useTranslation();

  const firstUnansweredQuestion = questionsWithAnswers?.find(
    (question) => question?.answers?.length === 0,
  );
  const isFirstUnansweredQuestion = (answer) =>
    firstUnansweredQuestion?.questionId === answer?.questionId;

  return (
    <Col>
      <h2 style={{ fontSize: 22, fontWeight: 500, marginBottom: 40 }}>
        {t("dashboardScreen.yourLifeQuestions")}
      </h2>
      {isLoading ? (
        <Col className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" />
        </Col>
      ) : (
        <div className="all-answers-container">
          {questionsWithAnswers &&
            questionsWithAnswers?.map((answer) => {
              return (
                <AnswerItem
                  key={`answer_${answer?.questionId}`}
                  data={answer}
                  current={isFirstUnansweredQuestion(answer)}
                />
              );
            })}
        </div>
      )}
    </Col>
  );
};

export default AllAnswers;
