import { useTranslation } from "react-i18next";

const WaitingForPrint = () => {
  const { t } = useTranslation();

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        className="text-center"
      >
        <h2>{t("dashboardScreen.congratulations")}</h2>
        <p>{t("dashboardScreen.soonWeWillSendYourBookToPrint")}</p>
      </div>
    </>
  );
};

export default WaitingForPrint;
