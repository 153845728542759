import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Suspense } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { auth } from "./firebase/firebase";
import Spinner from "react-bootstrap/Spinner";
import Container from "react-bootstrap/Container";
import Footer from "./components/Footer";
import LoginScreen from "./screens/LoginScreen";
import DashboardScreen from "./screens/DashboardScreen";
import ProfileScreen from "./screens/ProfileScreen";
import Navigation from "./components/Navigation";
import AdminScreen from "./screens/AdminScreen";

function App() {
  const [user, loading] = useAuthState(auth);

  return (
    <Suspense fallback="...loading">
      <Router>
        <div
          className="d-flex flex-column bg-body-tertiary"
          style={{ minHeight: "100vh" }}
        >
          <Navigation />
          <Container className="flex-grow-1">
            {loading ? (
              <Container
                style={{ marginTop: "25%" }}
                className="d-flex justify-content-center align-items-center"
              >
                <Spinner animation="border" />
              </Container>
            ) : user ? (
              <Routes>
                <Route path="/" element={<DashboardScreen />} />
                <Route path="/profile" element={<ProfileScreen />} />
                <Route path="/admin" element={<AdminScreen />} />
              </Routes>
            ) : (
              <LoginScreen />
            )}
          </Container>
          <Footer />
        </div>
      </Router>
    </Suspense>
  );
}

export default App;
