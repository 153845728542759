import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import {useState} from 'react';

const ErrorToast = ({ errorMessage }) => {
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);

  return (
    <ToastContainer position="top-end" className="p-3">
      <Toast bg="danger" onClose={handleClose} show={show} delay={5000} autohide>
        <Toast.Body className="text-white">{errorMessage}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default ErrorToast;
