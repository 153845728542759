import { useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useAuthState, useSignOut } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, updateUser } from "../firebase/firebase";
import ChangePassModal from "../components/ChangePassModal";
import Spinner from "react-bootstrap/Spinner";
import { useTranslation } from "react-i18next";
import ErrorToast from '../components/ErrorToast';
import {useUserData} from '../hooks/useUserData';

function ProfileScreen() {
  const [changePassModal, setChangePassModal] = useState(false);
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [user] = useAuthState(auth);
  const [signOut] = useSignOut(auth);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { userData } = useUserData();

  useEffect(() => {
    if (userData?.name) {
      setName(userData?.name);
    }
  }, [userData?.name]);

  const handleLogout = () => {
    navigate("/");
    signOut();
  };

  const handleUpdateUser = async (data) => {
    setLoading(true);

    try {
      const { uid, email } = user
      setError(null);
      await updateUser({ uid, data, email });
    } catch (error) {
      setError('Error updating document. Please try again.');
      console.error("Error updating document: ", error);
    } finally {
      setTimeout(() => setLoading(false), 1000);
    }
  };

  return (
    <div className="mt-4">
      <h4>{t("common.profile")}</h4>
      <Col sm={6} className="mt-2">
        <Form.Label>{t("profileScreen.fullName")}</Form.Label>
        <InputGroup>
          <Form.Control
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <Button variant="dark" onClick={() => handleUpdateUser({ name })}>
            {loading && <Spinner size="sm" style={{ marginRight: 8 }} />}{" "}
            {t("common.save")}
          </Button>
        </InputGroup>
      </Col>
      <Col sm={6} className="mt-3">
        <Form.Label>{t("common.email")}</Form.Label>
        <InputGroup>
          <Form.Control
            aria-label="Default"
            aria-describedby="inputGroup-sizing-default"
            value={user?.email}
            disabled
          />
        </InputGroup>
      </Col>
      <Col sm={6} className="mt-3">
        <Form.Label>{t("common.password")}</Form.Label>
        <InputGroup>
          <Form.Control
            aria-label="Default"
            aria-describedby="inputGroup-sizing-default"
            value="***********"
            disabled
          />
          <Button
            variant="dark"
            id="button-addon1"
            onClick={() => setChangePassModal(true)}
          >
            {t("common.change")}
          </Button>
        </InputGroup>
      </Col>
      <Col className="mb-4">
        <Button
          className="mt-4 button-update"
          onClick={handleLogout}
          size="md"
          variant="outline-dark"
        >
          {t("common.logout")}
        </Button>
      </Col>
      <ChangePassModal
        user={user}
        visible={changePassModal}
        onHide={() => {
          setChangePassModal(false);
        }}
      />
      {error && <ErrorToast errorMessage={error} />}
    </div>
  );
}

export default ProfileScreen;
