import { COVERS_WITHOUT_NAMES } from "../utils/data";
import ImageFromStorage from "./ImageFromStorage";

const CustomCover = ({ cover, coverPhoto, width, withPhoto, name, title }) => {
  return (
    <div style={{ position: "relative", width: 320 }}>
      <img
        style={{ width }}
        src={COVERS_WITHOUT_NAMES[cover - 1]}
        alt="memowrite book cover"
      />
      {withPhoto ? (
        <div
          style={{
            backgroundColor: "silver",
            width: 122,
            height: 150,
            position: "absolute",
            top: 98,
            left: 106,
          }}
        >
          {coverPhoto ? (
            <ImageFromStorage
              link={coverPhoto}
              height={150}
              width={122}
              objectFit="cover"
            />
          ) : (
            <div style={{ height: 150, width: 122 }}></div>
          )}
          {name && (
            <div
              style={{
                marginLeft: -20,
                marginRight: -20,
                marginTop: 10,
                height: 34,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h6
                style={{
                  color: String(cover).match(/2|5/g) ? "black" : "white",
                  fontSize: 14,
                  marginBottom: 0,
                  textAlign: "center",
                }}
              >
                {name}
              </h6>
            </div>
          )}
          {title && (
            <p
              style={{
                color: String(cover).match(/2|5/g) ? "black" : "white",
                fontSize: 8,
                marginTop: 2,
                marginBottom: 0,
                letterSpacing: 2,
                textAlign: "center",
              }}
            >
              {title.toUpperCase()}
            </p>
          )}
        </div>
      ) : (
        <>
          <div
            style={{
              position: "absolute",
              top: 145,
              left: 82,
              width: 170,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            {title && (
              <p
                style={{
                  color: String(cover).match(/8|11/g) ? "black" : "white",
                  fontSize: 8,
                  marginBottom: 0,
                  letterSpacing: 2,
                }}
              >
                {title.toUpperCase()}
              </p>
            )}
          </div>
          <div
            style={{
              position: "absolute",
              top: 163,
              left: 82,
              height: 70,
              width: 170,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            {name && (
              <h6
                style={{
                  color: String(cover).match(/8|11/g) ? "black" : "white",
                  fontSize: 22,
                  marginBottom: 0,
                }}
              >
                {name}
              </h6>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default CustomCover;
