import Progress from "../components/Progress";
import MainActivity from "../components/MainActivity";
import AllAnswers from "../components/AllAnswers";
import {useUserData} from '../hooks/useUserData';

function DashboardScreen() {
  const { loading, userQuestionsWithAnswers } = useUserData();

  return (
    <div className="mt-4">
      <Progress userQuestions={userQuestionsWithAnswers} />
      <MainActivity userQuestions={userQuestionsWithAnswers} />
      <div className="divider"></div>
      <AllAnswers
        isLoading={loading}
        questionsWithAnswers={userQuestionsWithAnswers}
      />
    </div>
  );
}

export default DashboardScreen;
