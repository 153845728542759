import { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { collection, addDoc } from "firebase/firestore";
import { firestore } from "../firebase/firebase";
import { auth } from "../firebase/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useTranslation } from "react-i18next";
import {SKIPPED_ANSWER_VALUE} from '../constants/common';
import ErrorToast from './ErrorToast';

const AddAnswer = ({ data, onAnswer }) => {
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState(null);

  const [user] = useAuthState(auth);
  const { t } = useTranslation();

  const handleInputChange = (e) => {
    const { value } = e.target;
    setInputValue(value);
  };

  const saveAnswer = async (value) => {
    try {
      setError(null);
      const answerRef = collection(firestore, "email-replies");
      const date = new Date();
      const dateString = date.toString();

      await addDoc(answerRef, {
        date: dateString,
        timestamp: Math.round(Date.now() / 1000),
        questionId: data?.questionId,
        sender: user?.email,
        "stripped-text": value,
      });

      onAnswer();
    } catch (error) {
      setError('Error updating document. Please try again.');
      console.error("Error updating document: ", error);
    } finally {
      setInputValue("");
    }
  };

  const handleSaveClick = () => {
    saveAnswer(inputValue);
  };

  const handleSkipClick = () => {
    saveAnswer(SKIPPED_ANSWER_VALUE);
  };

  return (
    <>
      <Form>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Control
            as="textarea"
            rows={12}
            value={inputValue}
            name="editAnswer"
            onChange={handleInputChange}
          />
        </Form.Group>
        <p style={{ fontSize: 12 }}>
          {t("dashboardScreen.characterCount")}{" "}
          <span style={{ color: inputValue?.length > 4500 ? "red" : "black" }}>
          {inputValue?.length}
        </span>{" "}
          / 4500
        </p>
        <Button
          className="button-update"
          style={{
            backgroundColor: "#60A680",
            borderColor: "#60A680",
          }}
          onClick={handleSaveClick}
        >
          {t("common.save")}
        </Button>
        <Button
          className="button-update"
          style={{
            backgroundColor: "#D38B25",
            borderColor: "#D38B25",
            marginLeft: 8
          }}
          onClick={handleSkipClick}
        >
          {t("dashboardScreen.skipQuestion")}
        </Button>
      </Form>
      {error && <ErrorToast errorMessage={error} />}
    </>
  );
};

export default AddAnswer;
