import { useState } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { addUser, auth } from "../firebase/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useTranslation } from "react-i18next";
import ErrorToast from './ErrorToast';

const AddName = () => {
  const [name, setName] = useState(null);
  const [error, setError] = useState(null);

  const { t, i18n } = useTranslation();
  const [user] = useAuthState(auth);

  const handleSave = async () => {
    try {
      setError(null);
      await addUser({
        uid: user?.uid,
        data: {
          name,
          access: true,
          email: user?.email,
          platform: "app",
          language: i18n?.resolvedLanguage,
        },
      });
    } catch (error) {
      setError('Error updating document. Please try again.');
      console.error("Error updating document: ", error);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        className="text-center"
      >
        <h2>{t("dashboardScreen.whatsYourFullName")}</h2>
        <p className="mb-4">{t("dashboardScreen.weWillUseYourFullName")}</p>
        <InputGroup className="mb-3" style={{ maxWidth: 400 }}>
          <Form.Control
            placeholder="Enter full name"
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <Button variant="dark" onClick={handleSave}>
            {t("common.save")}
          </Button>
        </InputGroup>
      </div>
      {error && <ErrorToast errorMessage={error} />}
    </>
  );
};

export default AddName;
