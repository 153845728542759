import { useTranslation } from "react-i18next";

const WaitingForReview = () => {
  const { t } = useTranslation();

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        className="text-center"
      >
        <h2>{t("dashboardScreen.ourTeamWillCompileBook")}</h2>
        <p>{t("dashboardScreen.thisWillTakeUpTo24Hours")}</p>
      </div>
    </>
  );
};

export default WaitingForReview;
