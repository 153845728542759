import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";

const Progress = ({ userQuestions }) => {
  const { t } = useTranslation();

  const total = userQuestions?.length;
  const answered = userQuestions?.filter(
    (question) => question?.answers?.length
  )?.length;
  const progress = answered && total ? Math.ceil((answered / total) * 100) : 0;

  return (
    <Col xs={12} className="progress-container">
      <p className="mb-0" style={{ fontSize: 18, fontWeight: 500 }}>
        {progress}% {t("dashboardScreen.complete")}
      </p>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <div style={{ flex: 1, marginRight: 16 }}>
          <div
            className="book-progress"
            style={{ width: "100%", backgroundColor: "#d2e2e2" }}
          >
            <div
              className="book-progress"
              style={{ width: `${progress}%`, backgroundColor: "#60A680" }}
            />
          </div>
        </div>
        <span style={{ color: "grey", fontSize: 18 }}>
          {answered}/{total}
        </span>
      </div>
    </Col>
  );
};

export default Progress;
