import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";

const ConfirmModal = ({ visible, onHide, title, text, icon, onConfirm }) => {
  const { t } = useTranslation();

  return (
    <Modal show={visible} onHide={onHide} centered>
      <Modal.Header closeButton style={{ border: "none" }} />
      <Modal.Body>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 12,
          }}
        >
          {icon}
          {title && (
            <span style={{ fontSize: 20, fontWeight: 700 }}>{title}</span>
          )}
        </div>
        {text && <p>{text}</p>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="dark" className="button-update" onClick={onConfirm}>
          {t("common.confirm")}
        </Button>
        <Button
          variant="outline-dark"
          className="button-update"
          onClick={onHide}
        >
          {t("common.cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
