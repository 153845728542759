import {useRef, useState} from 'react';
import BookHtmlTemplateQA from "./BookHtmlTemplateQA";
import { usePdf } from "../utils/pdf";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import {useUserData} from '../hooks/useUserData';

const ReviewModal = ({ visible, onClose, bookData, user, onSave }) => {
  const [editable, setEditable] = useState(false);
  const { userData } = useUserData(user);

  const bookRef = useRef(null);
  const { t } = useTranslation();

  const { createPdf, loading } = usePdf();

  if (!userData) return null;

  return (
    <Modal size="lg" fullscreen show={visible} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Review before publishing</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          <div
            className="mt-4"
            style={{ maxWidth: 690, marginRight: "auto", marginLeft: "auto" }}
          >
            <div className="bookHTML" ref={bookRef}>
              {bookData?.length > 0 && (
                <BookHtmlTemplateQA
                  data={bookData}
                  name={userData?.name}
                  editable={editable}
                />
              )}
            </div>
          </div>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={onClose}>
          Close
        </Button>
        <Button
          variant={editable ? "danger" : "outline-danger"}
          onClick={() => setEditable(!editable)}
        >
          Edit
        </Button>
        <Button variant="dark" onClick={onSave}>
          Save
        </Button>
        <Button
          disabled={loading || editable}
          variant="dark"
          onClick={() =>
            createPdf(bookRef.current, userData?.name, {
              title: t("book.myLifeStory"),
            })
          }
        >
          {loading ? "Loading..." : "Generate PDF"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReviewModal;
