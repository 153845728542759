import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LOGO from "../assets/memowrite_logo.svg";

import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const year = new Date().getFullYear();

  return (
    <footer className="mt-4 pb-4">
      <Container>
        <Row>
          <Col xs={12} sm={4} className="d-flex mb-3 footer-logo">
            <img src={LOGO} height="14" alt="memowrite logo" />
          </Col>
          <Col xs={12} sm={4}>
            <p className="text-center" style={{ fontSize: 14 }}>
              © {year} Memowrite. {t("footer.allRightsReserved")}
            </p>
          </Col>
          <Col xs={12} sm={4} className="d-flex footer-links">
            <span style={{ fontSize: 14 }}>
              <a
                className="text-dark"
                href="https://www.getmemowrite.com/terms-and-conditions/"
              >
                {t("footer.termsAndConditions")}
              </a>
              {" | "}
              <a
                className="text-dark"
                href="https://www.getmemowrite.com/privacy-policy"
              >
                {t("footer.privacyPolicy")}
              </a>
            </span>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
