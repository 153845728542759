import UsersTable from "../components/UsersTable";
import AnswersTable from "../components/AnswersTable";
import CustomerSupport from "../components/CustomerSupport";

function AdminScreen() {
  return (
    <div className="mt-4">
      <CustomerSupport />
      <UsersTable />
      <AnswersTable />
    </div>
  );
}

export default AdminScreen;
